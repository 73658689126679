$breakpoint-mobile: 768px;
$breakpoint-tablet: 1025px;
$breakpoint-desktop: 1200px;
$breakpoint-main-container: 1066px;

// mixins
@mixin dynamicTextSize($baseWidth, $baseFontSize: 16px, $minWidth: 320px) {
  @media (max-width: $baseWidth) and (min-width: $minWidth) {
    font-size: $baseFontSize / $baseWidth * 100vw;
  }
}
